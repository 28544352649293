

.current-order-display {
    height: 100%; /* Take full height */
    height: 400px; /* Adjust the height as needed */
    overflow-y: auto; /* Enable vertical scrolling */
}



.order-items {
    height: calc(100% - 100px); /* Subtract height for the banner and total price */
    overflow-y: auto; /* Enable scroll for items */
}




/* Update the banner styles */
.banner {
    background-color: #007bff; /* Change the banner color */
    color: #fff;
    text-align: center;
    padding: 10px 0;
    border-top-left-radius: 5px; /* Add border radius to the top left */
    border-top-right-radius: 5px; /* Add border radius to the top right */
    margin-bottom: 10px; /* Add margin at the bottom */
}

/* Adjust the total price styles */
.total-price {
    background-color: #f5f5f5;
    padding: 10px 0;
    margin-top: 10px; /* Add margin at the top */
    border-bottom-left-radius: 5px; /* Add border radius to the bottom left */
    border-bottom-right-radius: 5px; /* Add border radius to the bottom right */
}
