body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.top-bar {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-around; /* Change from space-between to space-around */
    padding: 20px;
}

.top-bar > div {
    width: 200px; /* Adjust the width as needed */
    text-align: center;
}

.button-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.button-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    margin-left: 230px;
    margin-right: 200px;
    margin-top: -20px;
}

.extra-space {
    height: 130px; /* Adjust the height to add more space */
}

.button {
    background-color: #333;
    color: #fff;
    padding: 30px 40px; /* Adjusted padding for larger buttons */
    border: none;
    cursor: pointer;
    margin: 10px; /* Adjusted margin for spacing between buttons */
    font-size: 20px;
    border-radius: 5px;
}

.order-text {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 130px;
}

.order-text p {
    margin: 10px 0; /* Adjusted margin for the <p> elements */
    margin-bottom: -10px;
}

.view-cart {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    padding: 20px 25px;
    border: none;
    cursor: pointer;
    font-size: 24px;
    border-radius: 5px;
}

.chatbot-box {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #333;
    color: #fff;
    padding: 20px 25px;
    border: none;
    cursor: pointer;
    font-size: 24px;
    transition: left 0.3s;
    border-radius: 5px;
}

.chatbot-box-right {
    left: auto; /* Reset the left value */
    right: 60%; /* Position from the right */
}

.cart-popup {
    display: none; /* Initially hidden */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1;
    text-align: center;
}

.cart-content {
    background: white;
    margin: 10% auto;
    padding: 20px;
    width: 30%;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Style the cart items list to remove bullet points */
#cartItems {
    list-style: none;
    padding: 0;
}

.custom-link {
    color: white;
    text-decoration: none;
}

.logout-button {
    border: none;
    cursor: pointer;
    border-radius: 5px;
}