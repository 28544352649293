/* Global Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

/* Styles for the top navigation bar */
.top-bar {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-around;
    padding: 20px;
}
/* Styles for each div inside the top navigation bar */
.top-bar > div {
    width: 100%;
    text-align: center;
}

/* Container for the login area */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

/* Box styling for the login area */
.login-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    margin-bottom: 10px;
}

/* Styling for login input fields */
.login-input {
    width: calc(100% - 10px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Styles for the login button */
.login-button {
    background-color: #333;
    color: #fff;
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
}

/* Styles for the navigation button */
.nav-button {
    background-color: #333;
    color: #fff;
    padding: 15px 30px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-right: 10px;
}

/* Paragraph text styling */

.login-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

p {
    font-size: 18px;
}
