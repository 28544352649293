@keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Apply the animation to the button when isSending is true */
  .sending-animation {
    animation: pulse 1s ease infinite;
  }
