.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure the popup appears on top of other content */
}

.popup-inner {
    background-color: #fff; /* Set the background color for the popup */
    padding: 20px;
    border-radius: 5px;
}