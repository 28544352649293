body {
    margin: 0; /* Remove default body margin */
    padding: 0; /* Remove default body padding */
    background: url("background-texture.png") center center fixed; /* Set the background image */
    background-size: cover; /* Cover the entire viewport */
    background-size: 35% auto; /* Zoom in by 10% horizontally, maintain aspect ratio vertically */
}

.content-container {
    z-index: 1; /* Content above the background */
    position: relative; 
  }

.background-image-container{
    /* Sets background image */
    background-image: url("background-texture.png");
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rounded-carousel-container {
    overflow: hidden;
    border-radius: 15px; /* Adjust the border-radius value as needed */
    margin: 5vh 10vw 5vh 10vw;
    width: 80vw;
    height: 80vh;
    align-items: center;
    justify-content: center;
}

.table-container {
    overflow: hidden;
    border-radius: 15px;
    margin: 5vh 5vh 5vh 5vh;
    align-items: center;
    justify-content: center;
    display: inline-block;
    width: 90vw;
    height: 90vh;
}

.dark-text {
    color: black;
}


.scrollable-tbody {
    max-height: 80vh; /* Adjust the height as needed */
    overflow-y: auto;
}

.scrollable-tbody thead th {
    position: sticky;
    top: 0;
    background-color: white; /* Set the background color to match your table background */
    z-index: 1;
  }

.input-field {
    margin: 15px 10px 10px 15px;
}  

.border-container {
    border: 1px;
    border-color: black;
}
  
#button-basic {
    color: white;
    margin: 0 20px 10px 20px;
}

.carousel-image {
    object-fit: fill; /* or "contain" based on your preference */
    height: 100%;
    width: 100%;
}