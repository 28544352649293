body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.top-bar {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-around; /* Change from space-between to space-around */
    padding: 20px;
}

.top-bar > div {
    width: 100%; /* Adjust the width as needed */
    text-align: center;
}

.item-container {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    margin: -25px 0;
    justify-content: center;
    margin-left: 120px;
    margin-right: 140px;
}

.item {
    text-align: center;
    margin: 10px;
    padding: 20px;
    margin: -10px;
}

.item-header {
    text-align: center;
    font-size: 30px;
    margin: 20px 0;
    margin-bottom: -20px;
}

.item-image {
    width: 160px;
    height: 200px;
    margin: 0 auto;
    margin-top: 20px;
}

.item-description {
    text-align: center;
    margin: 10px 0;
    width: 215px;
    margin-bottom: -5px;
}

.bold-text {
    font-weight: bold;
}

.item-button {
    background-color: #333;
    color: #fff;
    padding: 25px 35px;
    border: none;
    cursor: pointer;
    margin: 10px;
    font-size: 20px;
    width: 175px;
    align-items: center; /* Center text vertically */
    justify-content: center; /* Center text horizontally */
    margin-top: 20px;
    border-radius: 5px;
}

.view-cart {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    padding: 20px 25px;
    border: none;
    cursor: pointer;
    font-size: 24px;
    text-decoration: none; /* Remove the underline on the anchor */
    border-radius: 5px;
}

.view-cart:active {
    background-color: pink; /* Change background color to pink when clicked */
}

.view-cart-link {
    color: #fff; /* Set the text color explicitly to white */
    text-decoration: none; /* Remove the underline on the anchor */
}

.back {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #333;
    color: #fff;
    padding: 20px 40px;
    border: none;
    cursor: pointer;
    font-size: 24px;
    border-radius: 5px;
}

.cart-popup {
    display: none; /* Initially hidden */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1;
    text-align: center;
}

.cart-content {
    background: white;
    margin: 10% auto;
    padding: 20px;
    width: 30%;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Style the cart items list to remove bullet points */
#cartItems {
    list-style: none;
    padding: 0;
}

.custom-link {
    color: white;
    text-decoration: none;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}