
.exit-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
}

.popup-header {
    text-align: center;
    width: 100%;
}

.ingredient-section {
    display: flex;
    justify-content: space-between;
}

.default-ingredients,
.additional-ingredients {
    max-height: 200px; /* Set a maximum height for the ingredient sections */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 20px;
}

.ingredient-item {
    margin-bottom: 10px;
}

.send-order-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}
