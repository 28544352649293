

.top-bar {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-around; /* Change from space-between to space-around */
    padding: 20px;
}

.top-bar > div {
    width: 100%; 
    text-align: center;
}


  
  .menu-background {
    background-color: #fff;
    padding: 20px;
    margin-left: 200px;
    margin-right: 200px;
    margin-top: 50px;
    border-radius: 20px;
  }
.item-image {
    width: 160px;
    height: 200px;
    margin: 0 auto;
}

.item-description {
    text-align: center;
    margin: 10px 0;
    width: 215px;
}

.item-button {
    background-color: #333;
    color: #fff;
    padding: 25px 35px;
    border: none;
    cursor: pointer;
    margin: 10px;
}

.menu-header {
    text-align: center;
    margin: 20px 0;
    margin-bottom: 0px;
}

.custom-link {
    color: white;
    text-decoration: none;
}

.category-title {
    text-align: center;
    margin-bottom: 10px;
    margin-top: -25px;
}



  
  .item:hover .ingredients-window {
    display: block;
  }


  
  .item:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .item-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .item-image:hover {
    transform: scale(1.1);
  }
  
  .item-description {
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
    color: #333;
  }
  
  .item-price {
    font-size: 1.2em;
    color: #007bff; /* Change the color according to your preference */
  }
  
  /* Improved hover effect for ingredients window */
  .item:hover .ingredients-window {
    display: block;
  }

  .menu-header {
    text-align: center;
    margin-top: 20px;
    color: #333;
  }

  .menu-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  
  .ingredients-window {
    position: absolute;
    top: -10px;
    left: 100%;
    z-index: 2000000;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    display: none;
  }
  .item:hover .ingredients-window {
    display: block;
  }

  .category-wrapper {
    margin-bottom: 50px;
    margin-top: 50px;
  }
  
  .item-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }