body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.top-bar {
    background-color: #333;
    color: #fff;
    display: flex;
    justify-content: space-around; /* Change from space-between to space-around */
    padding: 20px;
}

.top-bar > div {
    width: 200px; /* Adjust the width as needed */
    text-align: center;
}

#cartHeader {
    font-size: 36px; /* Adjusted font size for the cart header */
    text-align: center;
    margin-top: 20px; /* Add margin at the top for spacing */
}

#cartItems {
    list-style: none; /* Remove bullet points */
    font-size: 20px; /* Adjusted font size for cart items */
    text-align: center;
    padding: 0; /* Remove default padding for ul */
}

#cartItems li {
    margin-bottom: 10px; /* Adjust the margin as needed */
}

.checkout-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    padding: 20px 25px;
    border: none;
    cursor: pointer;
    font-size: 24px;
    text-decoration: none; /* Remove the underline on the anchor */
    border-radius: 5px;
}

.order-text {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
}

.back {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #333;
    color: #fff;
    padding: 20px 40px;
    border: none;
    cursor: pointer;
    font-size: 24px;
    border-radius: 5px;
}

#totalPriceContainer {
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
    padding-bottom: 25px; /* Use padding-bottom instead of margin-bottom */
}

.delete-item-button {
    display: block;
    padding: 5px 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.delete-item-button {
    display: block;
    margin: 0 auto; /* Add this line to center the buttons horizontally */
    padding: 5px 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 10px;
}

.update-cart-button {
    display: block;
    margin: 0 auto; /* Add this line to center the buttons horizontally */
    padding: 5px 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 30px;
}

.cart-item {
    display: flex;
    justify-content: center; /* Center items horizontally */
    margin-bottom: -20px;
}

.ingredients {
    margin-top: 10px;
}