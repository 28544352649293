/* 
 * .chatbot-container-right class
 * Purpose: Positions the chatbot container at the bottom right of the viewport.
 * Features: 
 * - 'position: fixed' makes the container stay in the same place even when scrolling.
 * - 'bottom' and 'right' properties position the container 5px away from the bottom and right edges of the viewport.
 */
.chatbot-container-right {
  position: fixed;
  bottom: 5px;
  right: 5px;
}
/**
 * Styles for positioning the chatbot container on the bottom left of the screen.
 * Similar to .chatbot-container-right but aligned to the left.
 */
.chatbot-container-left {
  position: fixed;
  bottom: 5px;
  left: 5px;
}
/**
 * Styles for the chatbot toggle button.
 * Positioned fixed at the bottom right of the screen, with a higher z-index to ensure it's always visible.
 */
#chatbotToggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}