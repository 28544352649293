.popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ingredients-row {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.ingredients-column {
    margin: 0 20px;
    overflow-y: auto; 
    max-height: 200px; 
    border: 1px solid #ccc;
    padding: 5px;
}

.scrollable {
    overflow-y: auto;
}

.exit-popup-button {
    display: block;
    padding: 5px 10px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.add-to-cart-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.popup-header {
    text-align: center;
    width: 100%;
}

.default-ingredients,
.additional-ingredients {
    max-height: 200px; /* Set a maximum height for the ingredient sections */
    overflow-y: auto; /* Enable vertical scrolling */
    padding: 20px;
}