
.dashboard-container {
    position: fixed;
    bottom: 20px;
    left: 20px; 
    display: flex;
    flex-direction: row;
  }
  
  .dashboard-container button {
    margin-bottom: 5px; 
  }
  